import React from "react"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/styledComponents"
import CenterBorderedHeader from "../components/centerBorderedHeader"
import LastCTASection from "../components/lastCTASection"
import { graphql } from "gatsby"
import ContactForm from "../components/contactForm"
import {
  BigMobileFont,
  MediumMobileFont,
  SmallMobileFont,
} from "../components/typography"
import BaseImg from "../components/baseImg"

const TwoColumns = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => props.desktopMargin};
  @media (max-width: ${(props) => props.breakpoint}) {
    margin: 0;
    flex-direction: column;

    .gatsby-image-wrapper {
      height: auto !important;
      img {
        object-fit: contain !important;
      }
    }
  }
`

const ContactInfo = styled.div`
  padding: 32px;
  p {
    margin-bottom: 0.5rem;
  }
`

const FreeProjectEstimation = ({ data }) => {
  const {
    contactHeader,
    contactMail,
    contactName,
    contactMobile,
    contactRole,
    contactMailPrefix,
    contactMobilePrefix,
    contactSkype,
    contactFormTitle,
    firstSectionText,
    firstSectionTitle,
    lastCTASectionButtonText,
    lastCTASectionButtonUrl,
    lastCTASectionText,
    lastCTASectionTitle,
    contactImage,
  } = data.wordpressPage.cmb2.metabox_free_project_estimation
  return (
    <Layout>
      <SEO
        meta={data.wordpressPage.yoast_meta}
        title={data.wordpressPage.title}
        path="/free-project-estimation"
      />
      <Container>
        <CenterBorderedHeader isH1>
          <BigMobileFont desktopFontSize="2.5rem">
            {firstSectionTitle}
          </BigMobileFont>
        </CenterBorderedHeader>
        <div
          style={{
            maxWidth: "520px",
            margin: "40px auto",
            textAlign: "center",
          }}
        >
          <MediumMobileFont>{firstSectionText}</MediumMobileFont>
        </div>
        <TwoColumns breakpoint="900px">
          <TwoColumns breakpoint="1195px" desktopMargin="0 32px 0 0">
            <BaseImg
              style={{
                width: "280px",
                height: "auto",
                marginBottom: "0",
              }}
              localFile={contactImage.localFile}
              alt=""
            />
            <ContactInfo>
              <h2 style={{ marginBottom: "0.75rem" }}>
                <SmallMobileFont style={{ fontWeight: "700" }}>
                  {contactHeader}
                </SmallMobileFont>
              </h2>
              <p style={{ fontWeight: "700" }}>{contactName}</p>
              <p>{contactRole}</p>
              <p>
                {`${contactMailPrefix} `}
                <a style={{ fontWeight: "700" }} href={`mailto:${contactMail}`}>
                  {contactMail}
                </a>
              </p>
              <p>
                {`${contactMobilePrefix} `}
                <a style={{ fontWeight: "700" }} href={`tel:${contactMobile}`}>
                  {contactMobile}
                </a>
              </p>
            </ContactInfo>
          </TwoColumns>
          <ContactForm
            title={contactFormTitle}
            onSubmit={() => {
              trackCustomEvent({
                category: "Goals",
                action: "Darmowa wycena - wysłanie formularza",
              })
            }}
          />
        </TwoColumns>
      </Container>
      <LastCTASection
        title={lastCTASectionTitle}
        text={lastCTASectionText}
        buttonText={lastCTASectionButtonText}
        buttonUrl={lastCTASectionButtonUrl}
      />
    </Layout>
  )
}

export default FreeProjectEstimation

export const query = graphql`
  query FreeProjectEstimationPageQuery {
    wordpressPage(
      template: { eq: "page-templates/page-free-project-estimation.php" }
    ) {
      title
      yoast_meta {
        content {
          source_url
        }
        normalized_content
        name
        property
      }
      cmb2 {
        metabox_free_project_estimation {
          contactHeader
          contactMail
          contactMailPrefix
          contactMobilePrefix
          contactName
          contactMobile
          contactRole
          contactSkype
          contactFormTitle
          firstSectionText
          firstSectionTitle
          lastCTASectionButtonText
          lastCTASectionButtonUrl
          lastCTASectionText
          lastCTASectionTitle
          contactImage {
            localFile {
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
